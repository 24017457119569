let players = [
  {
    playerId: "001",
    playerName: "मनोज कुमार",
    playerFullName: "001 - मनोज कुमार",
    playerFatherName: "ओगड़राम जी पँवार",
    playerPhone: "9468900653",
    playerDob: "30-08-1989",
    playerAge: 35,
    playerSize: "XXL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1g3TEXoa2qMNYzC3gJHzAUsOMZdIL679X",
    playerStatus: "Available",
    playerSoldPrice: "20000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "002",
    playerName: "जीतेन्द्र पँवार",
    playerFullName: "002 - जीतेन्द्र पँवार",
    playerFatherName: "जेठाराम जी पँवार",
    playerPhone: "9460088701",
    playerDob: "10-03-1985",
    playerAge: 39,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1KTjfA49EyXPhgUVbqLAzLLr5WNhf28ms",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "003",
    playerName: "धीरेन पँवार",
    playerFullName: "003 - धीरेन पँवार",
    playerFatherName: "जीतेन्द्र पँवार",
    playerPhone: "9460088701",
    playerDob: "22-07-2013",
    playerAge: 11,
    playerSize: "S",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1-XMHhYKtQ-KYSipw53szyVjb_42YBjDL",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "004",
    playerName: "महेंद्र परिहार",
    playerFullName: "004 - महेंद्र परिहार",
    playerFatherName: "घीसूलाल जी परिहार",
    playerPhone: "7742785278",
    playerDob: "03-09-1995",
    playerAge: 29,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1RRU45n0VL0uOtqpk65_NcyqtikBg5tzI",
    playerStatus: "Available",
    playerSoldPrice: "12000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "005",
    playerName: "विनय भाटी",
    playerFullName: "005 - विनय भाटी",
    playerFatherName: "सुनील जी भाटी",
    playerPhone: "6378202062",
    playerDob: "11-07-2007",
    playerAge: 17,
    playerSize: "L",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1SXKH0KnQtd5Dys38RXn9AmxgQx_9Y6Fd",
    playerStatus: "Available",
    playerSoldPrice: "12000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "006",
    playerName: "महेंद्र परिहार",
    playerFullName: "006 - महेंद्र परिहार",
    playerFatherName: "गिरधारी लाल जी परिहार",
    playerPhone: "9571986016",
    playerDob: "21-09-1992",
    playerAge: 32,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1A5fMe0WVJQIh_16Um_ExYXWCr5ZXbbOh",
    playerStatus: "Available",
    playerSoldPrice: "7000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "007",
    playerName: "हेमन्त पँवार",
    playerFullName: "007 - हेमन्त पँवार",
    playerFatherName: "नारायणलाल जी पँवार",
    playerPhone: "9214022437",
    playerDob: "10-10-1985",
    playerAge: 39,
    playerSize: "L",
    playerRole: "विकेट कीपर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1pw6OmMrtYYejid30bFK4FKsNRTiUEVH4",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "008",
    playerName: "गौरव सोलंकी",
    playerFullName: "008 - गौरव सोलंकी",
    playerFatherName: "प्रकाश जी सोलंकी",
    playerPhone: "9549206231",
    playerDob: "20-11-2004",
    playerAge: 20,
    playerSize: "S",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1eSolxW6FBOLSCQAMT6cOx1vLNIH6YIyj",
    playerStatus: "Available",
    playerSoldPrice: "14000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "009",
    playerName: "अमृत ​​​​गहलोत",
    playerFullName: "009 - अमृत ​​​​गहलोत",
    playerFatherName: "ओगड़राम जी गहलोत",
    playerPhone: "6375162685",
    playerDob: "24-08-2008",
    playerAge: 16,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "13HlYCnHtkWWTSfggr9Vu67C2OSSrhX92",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "010",
    playerName: "रमेश परिहार",
    playerFullName: "010 - रमेश परिहार",
    playerFatherName: "रामलाल जी परिहार",
    playerPhone: "8080805250",
    playerDob: "07-08-1994",
    playerAge: 30,
    playerSize: "L",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1p5Hd9gUWjZWRik3A1FKUuFK6t9qmHJP4",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "011",
    playerName: "संजय भाटी",
    playerFullName: "011 - संजय भाटी",
    playerFatherName: "तोलाराम जी भाटी",
    playerPhone: "9680588256",
    playerDob: "09-11-1997",
    playerAge: 27,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1TFQzBRyHppx-VwN4R0oTZzPIzVE5RlKv",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "012",
    playerName: "चेतन प्रकाश देवड़ा",
    playerFullName: "012 - चेतन प्रकाश देवड़ा",
    playerFatherName: "अमरचंद जी देवड़ा",
    playerPhone: "9414610248",
    playerDob: "16-12-1983",
    playerAge: 40,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1czyJ7SONKZ1jcan8WTtrZvwnrME5h42a",
    playerStatus: "Available",
    playerSoldPrice: "7000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "013",
    playerName: "प्रियांशु भाटी",
    playerFullName: "013 - प्रियांशु भाटी",
    playerFatherName: "कैलाश जी भाटी",
    playerPhone: "6377945285",
    playerDob: "11-06-2008",
    playerAge: 16,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का तेज गेंदबाज",
    playerImage: "1ezURxrizgMtpMXuPCVEq3JpdC6oSK653",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "014",
    playerName: "छगन भाटी",
    playerFullName: "014 - छगन भाटी",
    playerFatherName: "विजयराज जी भाटी",
    playerPhone: "9929852200",
    playerDob: "20-08-1979",
    playerAge: 45,
    playerSize: "XXL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1-2S0lui0L-0d826ofaT3eiwqa-HHXsEy",
    playerStatus: "Available",
    playerSoldPrice: 1000,
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "015",
    playerName: "रोहन परिहार",
    playerFullName: "015 - रोहन परिहार",
    playerFatherName: "बालकिशन जी परिहार",
    playerPhone: "7878020835",
    playerDob: "24-10-2006",
    playerAge: 18,
    playerSize: "M",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1ZbqM8P8Rc79_bGivI6f67BXU59TOUAWT",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "016",
    playerName: "प्रकाश भाटी",
    playerFullName: "016 - प्रकाश भाटी",
    playerFatherName: "नरेंद्र जी भाटी",
    playerPhone: "8290710572",
    playerDob: "25-02-2005",
    playerAge: 19,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1wHOiDffL1Tw75wRFgylT5YbF_jzZ1bBD",
    playerStatus: "Available",
    playerSoldPrice: "6000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "017",
    playerName: "हितेश पँवार",
    playerFullName: "017 - हितेश पँवार",
    playerFatherName: "प्रतामल जी पँवार",
    playerPhone: "9269998029",
    playerDob: "14-08-2004",
    playerAge: 20,
    playerSize: "M",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1V-nTjWKvoP4BPS_hOaU4ajMRhkjfFrc2",
    playerStatus: "Available",
    playerSoldPrice: "2000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "018",
    playerName: "दिनेश भाटी",
    playerFullName: "018 - दिनेश भाटी",
    playerFatherName: "राजेंद्र जी भाटी",
    playerPhone: "9214423000",
    playerDob: "23-07-1987",
    playerAge: 37,
    playerSize: "M",
    playerRole: "विकेट कीपर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1zvVKFxGiA_69WBdLjG0e9Ln13VsDNlT9",
    playerStatus: "Available",
    playerSoldPrice: "17000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "019",
    playerName: "प्रवीण राठौड़",
    playerFullName: "019 - प्रवीण राठौड़",
    playerFatherName: "प्रतापराम जी राठौड़",
    playerPhone: "9602176075",
    playerDob: "16-07-2002",
    playerAge: 22,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1len1xvFWy4shTMFZUzk0E77vt8FGSYo6",
    playerStatus: "Available",
    playerSoldPrice: "5000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "020",
    playerName: "सूरज बोराणा",
    playerFullName: "020 - सूरज बोराणा",
    playerFatherName: "बाबूलाल जी बोराणा",
    playerPhone: "9461332654",
    playerDob: "15-08-1992",
    playerAge: 32,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1wkBzPPLAIBHAPuPPRHTp1-CRnkzrtUCS",
    playerStatus: "Available",
    playerSoldPrice: "19000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "021",
    playerName: "सोहनलाल भाटी",
    playerFullName: "021 - सोहनलाल भाटी",
    playerFatherName: "भोपाराम जी भाटी",
    playerPhone: "7737652498",
    playerDob: "26-03-1979",
    playerAge: 45,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "बाएँ हाथ का स्पिन ब्लोअर",
    playerImage: "12xa40oqyLRUYow9PuOxOqATYStOaDzcd",
    playerStatus: "Available",
    playerSoldPrice: "9000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "022",
    playerName: "गजेंद्र भाटी",
    playerFullName: "022 - गजेंद्र भाटी",
    playerFatherName: "केसाराम जी भाटी",
    playerPhone: "9829821453",
    playerDob: "21-07-1989",
    playerAge: 35,
    playerSize: "XXL",
    playerRole: "गेंदबाज",
    playerBatting: "-",
    playerBowling: "बाएं हाथ का तेज गेंदबाज",
    playerImage: "1mFblLiRhuGHTF-AaJw_l1-uJMGxixz5N",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "023",
    playerName: "अनिल परिहार",
    playerFullName: "023 - अनिल परिहार",
    playerFatherName: "किस्तूरचंद जी परिहार",
    playerPhone: "9461736399",
    playerDob: "20-12-1986",
    playerAge: 37,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1zSighobvW4PHD3uyajYQ5X_RhwY6HSpO",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "024",
    playerName: "समर परिहार",
    playerFullName: "024 - समर परिहार",
    playerFatherName: "हीरालाल जी परिहार",
    playerPhone: "9461736399",
    playerDob: "01-12-2011",
    playerAge: 13,
    playerSize: "S",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1At1kPVa3FIPvSgz3yxPgrq05f-gZQB7W",
    playerStatus: "Available",
    playerSoldPrice: "7000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "025",
    playerName: "मोहित देवड़ा",
    playerFullName: "025 - मोहित देवड़ा",
    playerFatherName: "किशोर जी देवड़ा",
    playerPhone: "9079225140",
    playerDob: "10-04-1999",
    playerAge: 25,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1hnNnC7faBZQQBT0rxnUjHLylTyweZPLn",
    playerStatus: "Available",
    playerSoldPrice: "5000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "026",
    playerName: "राहुल देवड़ा",
    playerFullName: "026 - राहुल देवड़ा",
    playerFatherName: "किशोर जी देवड़ा",
    playerPhone: "6378371402",
    playerDob: "13-07-2001",
    playerAge: 23,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1Yy0LV7BhFagst-9KU_348U0gdBrCT2GP",
    playerStatus: "Available",
    playerSoldPrice: "6000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "027",
    playerName: "अशोक कुमार भाटी",
    playerFullName: "027 - अशोक कुमार भाटी",
    playerFatherName: "घीसाराम जी भाटी",
    playerPhone: "9929686880",
    playerDob: "05-05-1991",
    playerAge: 33,
    playerSize: "XXL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1jwMgAtcG70BMjds5Op6ytYqrFFhx-Alj",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "028",
    playerName: "मुकेश निकुम",
    playerFullName: "028 - मुकेश निकुम",
    playerFatherName: "राणाराम जी",
    playerPhone: "9829531597",
    playerDob: "20-06-1986",
    playerAge: 38,
    playerSize: "XXL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1LCO15zef-F0LKCd1OvfPytaDcw6KpcGg",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "029",
    playerName: "श्रीश परिहार",
    playerFullName: "029 - श्रीश परिहार",
    playerFatherName: "पीताराम जी परिहार",
    playerPhone: "9001114789",
    playerDob: "04-03-1988",
    playerAge: 36,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1XrDdE9xgbD9nnKg8ZaDxtB6Gc44wqCqB",
    playerStatus: "Available",
    playerSoldPrice: '1000',
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "030",
    playerName: "रक्षित भाटी",
    playerFullName: "030 - रक्षित भाटी",
    playerFatherName: "रवि जी भाटी",
    playerPhone: "9636602199",
    playerDob: "13-11-2012",
    playerAge: 12,
    playerSize: "S",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1oemtyU-AtiTphnGVrkJto1o1fvEz8TjO",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "031",
    playerName: "प्रकाश परिहार",
    playerFullName: "031 - प्रकाश परिहार",
    playerFatherName: "उदाराम जी परिहार",
    playerPhone: "9460014004",
    playerDob: "26-07-1992",
    playerAge: 32,
    playerSize: "XL",
    playerRole: "गेंदबाज",
    playerBatting: "-",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1wnbo70Sabd6HsOlpRy17VWGCv_P9tvrZ",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "032",
    playerName: "महेश भाटी",
    playerFullName: "032 - महेश भाटी",
    playerFatherName: "ओमप्रकाश जी भाटी",
    playerPhone: "7737474374",
    playerDob: "08-11-1993",
    playerAge: 31,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का तेज गेंदबाज",
    playerImage: "1Ds159mdd9MVUBKNK5zDn2UwSlUJvMKDT",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "-",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "033",
    playerName: "अरुण पँवार",
    playerFullName: "033 - अरुण पँवार",
    playerFatherName: "रत्नाराम जी पँवार",
    playerPhone: "9468901672",
    playerDob: "28-04-1995",
    playerAge: 29,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1LMXkhT43y-AEjWY5IwggMx3CWvKLG_NL",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "034",
    playerName: "नमन सोलंकी",
    playerFullName: "034 - नमन सोलंकी",
    playerFatherName: "योगेश जी सोलंकी",
    playerPhone: "8949496525",
    playerDob: "29-06-2007",
    playerAge: 17,
    playerSize: "S",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1RzVKumXeaqYrglNAKyZNr2yPpM6Mx5YX",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "035",
    playerName: "हेतन परिहार",
    playerFullName: "035 - हेतन परिहार",
    playerFatherName: "प्रकाश जी परिहार",
    playerPhone: "",
    playerDob: "",
    playerAge: "",
    playerSize: "S",
    playerRole: "",
    playerBatting: "",
    playerBowling: "",
    playerImage: "1dbFWPJa0IYVh4-a64LAyTW5xUuugVLJb",
    playerStatus: "Available",
    playerSoldPrice: 1000,
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "036",
    playerName: "धैर्य पँवार",
    playerFullName: "036 - धैर्य पँवार",
    playerFatherName: "रवीन्द्र जी पँवार",
    playerPhone: "9414121377",
    playerDob: "",
    playerAge: "",
    playerSize: "",
    playerRole: "बल्लेबाज",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "16YgWwnUgLmLe8j50PMAaaAP7Jz5lKDie",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "037",
    playerName: "खुशवंत सोलंकी",
    playerFullName: "037 - खुशवंत सोलंकी",
    playerFatherName: "भंवरलाल जी सोलंकी",
    playerPhone: "9214451846",
    playerDob: "20-11-1984",
    playerAge: 40,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का गेंदबाज",
    playerImage: "1I1QQzFoRPZ6Y5ojILtNMhcLgQuKQBU9c",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "038",
    playerName: "चंद्रप्रकाश भाटी",
    playerFullName: "038 - चंद्रप्रकाश भाटी",
    playerFatherName: "राकेश जी भाटी",
    playerPhone: "9772167780",
    playerDob: "31-08-1996",
    playerAge: 28,
    playerSize: "L",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1ZITXcrmYzTN994gW__-3GANTheNyZSp7",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "039",
    playerName: "अनिरुद्ध भाटी",
    playerFullName: "039 - अनिरुद्ध भाटी",
    playerFatherName: "राकेश जी भाटी",
    playerPhone: "9928844907",
    playerDob: "01-01-2000",
    playerAge: 24,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "13YX1NhiVWgw5WNcWBpSNnGxXZgq6Ic2d",
    playerStatus: "Available",
    playerSoldPrice: "12000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "040",
    playerName: "अनुराग परिहार",
    playerFullName: "040 - अनुराग परिहार",
    playerFatherName: "भीमराज जी परिहार",
    playerPhone: "6353099619",
    playerDob: "29-08-2002",
    playerAge: 22,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "12oF3EOPMbQ2_OUajw4vM3mXFO_sJn6xQ",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "041",
    playerName: "राहुल परिहार",
    playerFullName: "041 - राहुल परिहार",
    playerFatherName: "तेजराज जी परिहार",
    playerPhone: "7073756412",
    playerDob: "07-03-1999",
    playerAge: 25,
    playerSize: "L",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1PL_UMn7p8ojLQU2QEek33jnoj3EmxjmA",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "042",
    playerName: "पंकज सोलंकी",
    playerFullName: "042 - पंकज सोलंकी",
    playerFatherName: "रामलाल जी सोलंकी",
    playerPhone: "9116591249",
    playerDob: "27-02-1999",
    playerAge: 25,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "16P-jxco4Iu1343GMVia2sV3Zb-qlUfHw",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "043",
    playerName: "शांतिलाल पँवार",
    playerFullName: "043 - शांतिलाल पँवार",
    playerFatherName: "कालूराम जी पँवार",
    playerPhone: "7726956870",
    playerDob: "27-07-1994",
    playerAge: 30,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "10ABr4KGluOqk0_gAmsIuE-cBjzfsg1ST",
    playerStatus: "Available",
    playerSoldPrice: "25000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "044",
    playerName: "अशोक राठौड़",
    playerFullName: "044 - अशोक राठौड़",
    playerFatherName: "धनाराम जी राठौड़",
    playerPhone: "7742785278",
    playerDob: "15-01-1990",
    playerAge: 34,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का तेज गेंदबाज",
    playerImage: "1SIA8EkPTxU5PCOWVuUUIqLOYWx5Mzimz",
    playerStatus: "Available",
    playerSoldPrice: "7000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "045",
    playerName: "पिंटू पँवार",
    playerFullName: "045 - पिंटू पँवार",
    playerFatherName: "लक्ष्मण जी पँवार",
    playerPhone: "8005544077",
    playerDob: "14-10-2004",
    playerAge: 20,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1cxxl9-c5ZV6y1k2vhDZnVs6wxGuZFJHn",
    playerStatus: "Available",
    playerSoldPrice: "17000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "046",
    playerName: "संजय पँवार",
    playerFullName: "046 - संजय पँवार",
    playerFatherName: "ओमप्रकाश जी पँवार",
    playerPhone: "7425012590",
    playerDob: "08-10-2003",
    playerAge: 21,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1t3qFl1ezlD8lQlpJ11-3yhtSofF74t__",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "047",
    playerName: "दीपक पँवार",
    playerFullName: "047 - दीपक पँवार",
    playerFatherName: "श्यामलाल जी पँवार",
    playerPhone: "8890216903",
    playerDob: "17-05-2000",
    playerAge: 24,
    playerSize: "M",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "बाएँ हाथ का स्पिन ब्लोअर",
    playerImage: "1bFfARsfw4V3mgVsU3MFIb6RHEtBglSVB",
    playerStatus: "Available",
    playerSoldPrice: "12000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "048",
    playerName: "सुनील पँवार",
    playerFullName: "048 - सुनील पँवार",
    playerFatherName: "श्यामलाल जी पँवार",
    playerPhone: "9352511603",
    playerDob: "06-06-2001",
    playerAge: 23,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1lN7HtatYPeVFcriZwYHRwGMuhJ-Y_yGF",
    playerStatus: "Available",
    playerSoldPrice: "23000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "049",
    playerName: "दिशांतचंद परिहार",
    playerFullName: "049 - दिशांतचंद परिहार",
    playerFatherName: "सुरेशचंद जी परिहार",
    playerPhone: "8619494915",
    playerDob: "18-11-2011",
    playerAge: 13,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "14HDbzb90v2C7i0SkWFX8gdDxXu3XGr-W",
    playerStatus: "Available",
    playerSoldPrice: "6000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "050",
    playerName: "सुरेशचंद परिहार",
    playerFullName: "050 - सुरेशचंद परिहार",
    playerFatherName: "ओगड़राम जी परिहार",
    playerPhone: "9414122178",
    playerDob: "05-07-1983",
    playerAge: 41,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1OW9Mp-VptymvweHtDHGiW-Wt77L6UCud",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "051",
    playerName: "ओमाराम परमार",
    playerFullName: "051 - ओमाराम परमार",
    playerFatherName: "घीसाराम जी परमार",
    playerPhone: "8561828291",
    playerDob: "05-06-1991",
    playerAge: 33,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1xjRH1YqwMAoRZ7Nfrsjx48DG_4LXBWbS",
    playerStatus: "Available",
    playerSoldPrice: "6000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "052",
    playerName: "पंकज भाटी",
    playerFullName: "052 - पंकज भाटी",
    playerFatherName: "फूलचंद जी भाटी",
    playerPhone: "9982424000",
    playerDob: "01-01-1988",
    playerAge: 36,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1kP3Ar6Oo0zncP1oZVaKgx_4Cj8Ig_fUo",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "053",
    playerName: "प्रतापराम परमार",
    playerFullName: "053 - प्रतापराम परमार",
    playerFatherName: "घीसाराम जी परमार",
    playerPhone: "8619065380",
    playerDob: "05-04-1989",
    playerAge: 35,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "15oh7qWzJJQOzNGKSY1LpKb2V7x179F_8",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "054",
    playerName: "जिग्नेश कुमार भाटी",
    playerFullName: "054 - जिग्नेश कुमार भाटी",
    playerFatherName: "धर्माराम जी भाटी",
    playerPhone: "9509490249",
    playerDob: "20-09-1999",
    playerAge: 25,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1jLgiHnG3rpFJdzEpqdNZvE9wga6XpDpu",
    playerStatus: "Available",
    playerSoldPrice: "16000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "055",
    playerName: "पिंटूराम भाटी",
    playerFullName: "055 - पिंटूराम भाटी",
    playerFatherName: "भीमाराम जी भाटी",
    playerPhone: "7427843193",
    playerDob: "20-08-1999",
    playerAge: 25,
    playerSize: "L",
    playerRole: "विकेट कीपर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1Zm_mXypB0cdFJjHek4mop_YSIw-6n_Wm",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "056",
    playerName: "प्रवीण परिहार",
    playerFullName: "056 - प्रवीण परिहार",
    playerFatherName: "भंवरलाल परिहार",
    playerPhone: "7014962324",
    playerDob: "03-03-1996",
    playerAge: 28,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का तेज गेंदबाज",
    playerImage: "166ESyA3oDgahXf7qwssrIJtI_pdp5X5K",
    playerStatus: "Available",
    playerSoldPrice: "20000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "057",
    playerName: "अनिल सोलंकी",
    playerFullName: "057 - अनिल सोलंकी",
    playerFatherName: "घेवरराम जी सोलंकी",
    playerPhone: "7014382272",
    playerDob: "28-10-1997",
    playerAge: 27,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का तेज गेंदबाज",
    playerImage: "1HCWFMTbfOE2uW5UOPVaC8JRhDTz4euDq",
    playerStatus: "Available",
    playerSoldPrice: "12000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "058",
    playerName: "आयुष भाटी",
    playerFullName: "058 - आयुष भाटी",
    playerFatherName: "जोगेंद्र जी भाटी",
    playerPhone: "7726966990",
    playerDob: "18-01-2005",
    playerAge: 19,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1OXKtKfZeIscqdF-kYfWq5hmbtQzJFbu0",
    playerStatus: "Available",
    playerSoldPrice: "13000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "059",
    playerName: "सोहन पँवार",
    playerFullName: "059 - सोहन पँवार",
    playerFatherName: "मुन्नालाल जी पँवार",
    playerPhone: "9252952479",
    playerDob: "10-10-1982",
    playerAge: 42,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1dmazDQy9QrYWYlQ91C99S1VQpQehFInH",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "060",
    playerName: "विनोद भाटी",
    playerFullName: "060 - विनोद भाटी",
    playerFatherName: "वेनाराम जी भाटी",
    playerPhone: "8290452418",
    playerDob: "15-01-1994",
    playerAge: 30,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1TUhdtQKavW6H-oKAY2nCdi_-iNFaYPmE",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "061",
    playerName: "राजवीर बोराणा",
    playerFullName: "061 - राजवीर बोराणा",
    playerFatherName: "अशोक कुमार बोराणा",
    playerPhone: "7877396447",
    playerDob: "21-04-2008",
    playerAge: 16,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएँ हाथ का स्पिन ब्लोअर",
    playerImage: "1ACZ3gapQKN0iqkdMP9Tf5uUNp5W9P0GB",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "062",
    playerName: "नकुल देवड़ा",
    playerFullName: "062 - नकुल देवड़ा",
    playerFatherName: "भंवरलाल जी देवड़ा",
    playerPhone: "9251041641",
    playerDob: "14-11-1985",
    playerAge: 39,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1pnSLhZalIr3lwrSBdtAiNunR1J1FzNYb",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "063",
    playerName: "कुणाल भाटी",
    playerFullName: "063 - कुणाल भाटी",
    playerFatherName: "बस्तीराम जी भाटी",
    playerPhone: "8003338288",
    playerDob: "21-02-1994",
    playerAge: 30,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1bBqBknc8xxafebZ_JU4zdKeBetA0_srL",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "064",
    playerName: "प्रकाशचंद पँवार",
    playerFullName: "064 - प्रकाशचंद पँवार",
    playerFatherName: "नारायण लाल जी पँवार",
    playerPhone: "9929831000",
    playerDob: "14-06-1987",
    playerAge: 37,
    playerSize: "XXXL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1IkN2R-e3iM6yOK-rYhtqqkmjT3e2M8l0",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "065",
    playerName: "प्रमोद बोराणा",
    playerFullName: "065 - प्रमोद बोराणा",
    playerFatherName: "मोहनलाल बोराणा",
    playerPhone: "9460819199",
    playerDob: "15-08-1987",
    playerAge: 37,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "16-fZHNVEvv3ON6xIkCOKTQwDvjvxha5c",
    playerStatus: "Available",
    playerSoldPrice: "15000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "066",
    playerName: "ओमप्रकाश परिहारिया",
    playerFullName: "066 - ओमप्रकाश परिहारिया",
    playerFatherName: "भंवरलाल जी परिहारिया",
    playerPhone: "6367820460",
    playerDob: "23-09-2005",
    playerAge: 19,
    playerSize: "M",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1GcNmLKU6kpc5svSpLjIO7NwR0SJ-6UEi",
    playerStatus: "Available",
    playerSoldPrice: "6000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "067",
    playerName: "अशोक परिहारिया",
    playerFullName: "067 - अशोक परिहारिया",
    playerFatherName: "मोहनलाल जी परिहारिया",
    playerPhone: "8107498116",
    playerDob: "29-01-2004",
    playerAge: 20,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1fbky2ss0ksInDcBvlDzS__6RikDQRVVg",
    playerStatus: "Available",
    playerSoldPrice: "9000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "068",
    playerName: "किशन परमार",
    playerFullName: "068 - किशन परमार",
    playerFatherName: "रूपाराम जी परमार",
    playerPhone: "9602760275",
    playerDob: "20-01-2000",
    playerAge: 24,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का गेंदबाज",
    playerImage: "1gCtGdUr---69ZP8LuQJDK5IsG5QWVhTF",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "069",
    playerName: "रमेश परिहार",
    playerFullName: "069 - रमेश परिहार",
    playerFatherName: "ओगड़राम जी परिहार",
    playerPhone: "9950318909",
    playerDob: "31-10-1985",
    playerAge: 39,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1RAuxELNerWhh5rJs6s3j2Zo7-b0yEZV6",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "070",
    playerName: "वीरेंद्र भाटी",
    playerFullName: "070 - वीरेंद्र भाटी",
    playerFatherName: "मोहनलाल जी भाटी",
    playerPhone: "9549845000",
    playerDob: "19-04-1988",
    playerAge: 36,
    playerSize: "XL",
    playerRole: "विकेट कीपर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1xfldyZ2iOUC955VZ8aj__TAJ2BJGcuuV",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "071",
    playerName: "दिनेश परिहार",
    playerFullName: "071 - दिनेश परिहार",
    playerFatherName: "ओगड़राम जी परिहार",
    playerPhone: "7014450259",
    playerDob: "01-12-1982",
    playerAge: 42,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "15ligeyRtiXo4L3Y0bmOUuHhbjJCy0tOr",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "072",
    playerName: "हर्षित भाटी",
    playerFullName: "072 - हर्षित भाटी",
    playerFatherName: "लक्ष्मीनारायण जी भाटी",
    playerPhone: "8233021063",
    playerDob: "20-10-2011",
    playerAge: 13,
    playerSize: "S",
    playerRole: "ऑल राउंडर",
    playerBatting: "LBH",
    playerBowling: "-",
    playerImage: "1nCHitho881saxx-Ecj83cexCF9ThhgtO",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "073",
    playerName: "विक्रम सोलंकी",
    playerFullName: "073 - विक्रम सोलंकी",
    playerFatherName: "मोहनलाल जी सोलंकी",
    playerPhone: "7597842523",
    playerDob: "22-12-1990",
    playerAge: 33,
    playerSize: "XL",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "  1ErvpQK86-rOBGihFQ0La6Y0tmf8kcllU",
    playerStatus: "Available",
    playerSoldPrice: "5000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "074",
    playerName: "विनोद भाटी",
    playerFullName: "074 - विनोद भाटी",
    playerFatherName: "कानाराम जी भाटी",
    playerPhone: "6376467566",
    playerDob: "15-05-2005",
    playerAge: 19,
    playerSize: "",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1lz-DyjcI75Zg1vPmmZ-i2lRvADi04eUI",
    playerStatus: "Available",
    playerSoldPrice: "7000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "075",
    playerName: "ललित पँवार",
    playerFullName: "075 - ललित पँवार",
    playerFatherName: "राणाराम जी पँवार",
    playerPhone: "7568234970",
    playerDob: "18-06-2000",
    playerAge: 24,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1ZJG4xquOEAX9HGanO7ZvTWShkuhUIZqG",
    playerStatus: "Available",
    playerSoldPrice: "11000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "076",
    playerName: "अरविन्द भाटी",
    playerFullName: "076 - अरविन्द भाटी",
    playerFatherName: "उदाराम जी भाटी",
    playerPhone: "7014002334",
    playerDob: "01-09-1988",
    playerAge: 36,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1shj9SviR-_uzqV25M3xeL2fF929Pyn9W",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "077",
    playerName: "रमन भाटी",
    playerFullName: "077 - रमन भाटी",
    playerFatherName: "सुनील जी भाटी",
    playerPhone: "8955837198",
    playerDob: "28-03-2005",
    playerAge: 19,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1A_IGexs3Vz50CdTgtWJenujTEKJx0P11",
    playerStatus: "Available",
    playerSoldPrice: "2000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "078",
    playerName: "विपुल भाटी",
    playerFullName: "078 - विपुल भाटी",
    playerFatherName: "सुनील जी भाटी",
    playerPhone: "6378202062",
    playerDob: "22-11-2010",
    playerAge: 14,
    playerSize: "S",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1m1F2G7_vXu0B__uprB3xE_szjVJlFvja",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "079",
    playerName: "महेंद्र परिहार",
    playerFullName: "079 - महेंद्र परिहार",
    playerFatherName: "भीमाराम जी परिहार",
    playerPhone: "9929825001",
    playerDob: "20-08-1990",
    playerAge: 34,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1SnDlF8-qYkd0b7RlsVGSwXUizLWmnD5e",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "080",
    playerName: "गणपत परमार",
    playerFullName: "080 - गणपत परमार",
    playerFatherName: "मुकनाराम जी परमार",
    playerPhone: "9601766929",
    playerDob: "20-03-1993",
    playerAge: 31,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का गेंदबाज",
    playerImage: "1VQ7kgSW8bu7h4bnirtpO51el1FuPtJFf",
    playerStatus: "Available",
    playerSoldPrice: "16000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "081",
    playerName: "सुभाष",
    playerFullName: "081 - सुभाष",
    playerFatherName: "मुकनाराम जी",
    playerPhone: "7976134335",
    playerDob: "01-01-1995",
    playerAge: 29,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1AsV7z9zz14sXtToWItSpcbLFOzqN5QFQ",
    playerStatus: "Available",
    playerSoldPrice: "27000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "082",
    playerName: "मुकेश परमार",
    playerFullName: "082 - मुकेश परमार",
    playerFatherName: "कैलाश जी परमार",
    playerPhone: "9971597382",
    playerDob: "23-11-1993",
    playerAge: 31,
    playerSize: "M",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1l0nBeand3Ia6uAwIXpedYd1Rk1DNHGAG",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "083",
    playerName: "अविनाश लाटेचा",
    playerFullName: "083 - अविनाश लाटेचा",
    playerFatherName: "हंसराज जी लाटेचा",
    playerPhone: "9929851120",
    playerDob: "23-11-1989",
    playerAge: 35,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "14gukPidYKj3B5A0z03314iWPIZQ5-WpW",
    playerStatus: "Available",
    playerSoldPrice: "2000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "084",
    playerName: "राजेश पँवार",
    playerFullName: "084 - राजेश पँवार",
    playerFatherName: "दलपतराज जी पँवार",
    playerPhone: "8385881102",
    playerDob: "03-08-1986",
    playerAge: 38,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1BagiCFMFKC0ArkU9q8TUxpX9-VTtlXe_",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "किसान एलेवन्स",
  },
  {
    playerId: "085",
    playerName: "नमन परमार",
    playerFullName: "085 - नमन परमार",
    playerFatherName: "ताराचंद जी परमार",
    playerPhone: "9078509235",
    playerDob: "07-02-1998",
    playerAge: 26,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का गेंदबाज",
    playerImage: "152chLwWfd0gp-sEqk10MHv9R2Mz7ESr7",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "086",
    playerName: "रोहित पँवार",
    playerFullName: "086 - रोहित पँवार",
    playerFatherName: "कैलाश जी पँवार",
    playerPhone: "7975485371",
    playerDob: "11-01-1995",
    playerAge: 29,
    playerSize: "XL",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का गेंदबाज",
    playerImage: "1FtJSKTQUNRM1DwWePtN02vnM-B6q6F6T",
    playerStatus: "Available",
    playerSoldPrice: 0,
    teamId: "",
    teamName: "",
  },
  {
    playerId: "087",
    playerName: "गिरीश पँवार",
    playerFullName: "087 - गिरीश पँवार",
    playerFatherName: "कालूराम जी पँवार",
    playerPhone: "7737923108",
    playerDob: "07-01-1993",
    playerAge: 31,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1DgN_Kg58K9ZOuy0uG8Panpkq3NLgTQRm",
    playerStatus: "Available",
    playerSoldPrice: "18000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "088",
    playerName: "गौरव कुमार देवड़ा",
    playerFullName: "088 - गौरव कुमार देवड़ा",
    playerFatherName: "गंगाराम जी देवड़ा",
    playerPhone: "7014463236",
    playerDob: "22-12-1983",
    playerAge: 40,
    playerSize: "XXL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1F0U7iUW0dRmW5x5p6A6v-xBqz8mNeQdq",
    playerStatus: "Available",
    playerSoldPrice: "3000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "089",
    playerName: "रवि भाटी",
    playerFullName: "089 - रवि भाटी",
    playerFatherName: "रामनारायण जी भाटी",
    playerPhone: "9461092003",
    playerDob: "26-06-1992",
    playerAge: 32,
    playerSize: "L",
    playerRole: "गेंदबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1ukL-SRGdg0hKs1NOKi4QlTxlW0dVwo6_",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "090",
    playerName: "योगेश भाटी",
    playerFullName: "090 - योगेश भाटी",
    playerFatherName: "अशोक जी भाटी",
    playerPhone: "9261717505",
    playerDob: "03-10-1992",
    playerAge: 32,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1_9qPkM1NdGXnF1GLdQ-2u6b7bfTrKZQu",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "091",
    playerName: "ललित भाटी",
    playerFullName: "091 - ललित भाटी",
    playerFatherName: "घेवर जी भाटी",
    playerPhone: "8209806529",
    playerDob: "11-11-2000",
    playerAge: 24,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1kbbSK3wgI9nia315NEr4WuwEsHBEmxOK",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "092",
    playerName: "दुर्गेश बोराणा",
    playerFullName: "092 - दुर्गेश बोराणा",
    playerFatherName: "भीमाराम जी बोराणा",
    playerPhone: "8209948477",
    playerDob: "28-02-1998",
    playerAge: 26,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "17oCuVbCnrTuyzFGIzkF065lsgA4c0kMe",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "093",
    playerName: "प्रभुदयाल परिहार",
    playerFullName: "093 - प्रभुदयाल परिहार",
    playerFatherName: "छगनलाल जी परिहार",
    playerPhone: "8078663580",
    playerDob: "03-03-1988",
    playerAge: 36,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1Xk8u0Ia5OWvBInsNlyN1W46bSujK0kvu",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "094",
    playerName: "कपिल परमार",
    playerFullName: "094 - कपिल परमार",
    playerFatherName: "नेकाराम जी परमार",
    playerPhone: "9828853369",
    playerDob: "24-04-1990",
    playerAge: 34,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का गेंदबाज",
    playerImage: "1eRZVY1FhI5y0CASlAyoePFxf4VkLXraI",
    playerStatus: "Available",
    playerSoldPrice: "18000",
    teamId: "",
    teamName: "गणपति सुपर किंग्स",
  },
  {
    playerId: "095",
    playerName: "नरसिंग परिहार",
    playerFullName: "095 - नरसिंग परिहार",
    playerFatherName: "कानाराम जी परिहार",
    playerPhone: "7231090744",
    playerDob: "11-02-1987",
    playerAge: 37,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "15in4ekWR4yqqzp4xjJ5dMKQ5A0jidPIA",
    playerStatus: "Available",
    playerSoldPrice: "15000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "096",
    playerName: "हेमंत गेहलोत",
    playerFullName: "096 - हेमंत गेहलोत",
    playerFatherName: "जीतेन्द्र कुमार गेहलोत",
    playerPhone: "8432171060",
    playerDob: "25-03-2010",
    playerAge: 14,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1CDORbJdFAuinOxUsdPh0uyJ5WgbGwwnH",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "097",
    playerName: "सुजल भाटी",
    playerFullName: "097 - सुजल भाटी",
    playerFatherName: "जोगेंद्र भाटी",
    playerPhone: "9351691000",
    playerDob: "15-11-2010",
    playerAge: 14,
    playerSize: "S",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1kByiSi12_mv7SfoV3wvqLVCTFScUeIbg",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "098",
    playerName: "भरत कुमार भाटी",
    playerFullName: "098 - भरत कुमार भाटी",
    playerFatherName: "बस्तीराम जी भाटी",
    playerPhone: "9602711152",
    playerDob: "09-08-1990",
    playerAge: 34,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "10ikRf2BpwNHmQWgnDdXKi0DaN3BTWBOQ",
    playerStatus: "Available",
    playerSoldPrice: "5000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "099",
    playerName: "जोगेंद्र पँवार",
    playerFullName: "099 - जोगेंद्र पँवार",
    playerFatherName: "मोहनलाल जी पँवार",
    playerPhone: "9414651808",
    playerDob: "22-06-1991",
    playerAge: 33,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1uyYsXDaxm5bWSmy0gi86WLZiLm3mbkk2",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "100",
    playerName: "हुक्मीचंद पँवार",
    playerFullName: "100 - हुक्मीचंद पँवार",
    playerFatherName: "मदन लाल जी पँवार",
    playerPhone: "9314796500",
    playerDob: "15-01-1987",
    playerAge: 37,
    playerSize: "XXXL",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1OGruVUiWIAHAOyOFUsK84O6uiEAJes21",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "101",
    playerName: "भरत",
    playerFullName: "101 - भरत",
    playerFatherName: "राजाराम जी",
    playerPhone: "8464400007",
    playerDob: "23-06-1993",
    playerAge: 31,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "148jrXowremMkvgyQkiDlebyriAn6IRwh",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "102",
    playerName: "हेमन्त पँवार",
    playerFullName: "102 - हेमन्त पँवार",
    playerFatherName: "ओगड़राम जी पँवार",
    playerPhone: "9252710039",
    playerDob: "24-03-1987",
    playerAge: 37,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ का गेंदबाज",
    playerImage: "1ZoPH9qgZLe2IlqrAX8EYvwUiTF5gUuy0",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "जीसीसी रॉयल्स",
  },
  {
    playerId: "103",
    playerName: "अमित सोलंकी",
    playerFullName: "103 - अमित सोलंकी",
    playerFatherName: "लक्मण जी सोलंकी",
    playerPhone: "9252424590",
    playerDob: "04-06-1995",
    playerAge: 29,
    playerSize: "XL",
    playerRole: "ऑल राउंडर",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "बाएं हाथ का तेज गेंदबाज",
    playerImage: "1F9LJFTcnamhDopI9TNHwmR9JOEnfjLUq",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "माँ तनोड क्लब",
  },
  {
    playerId: "104",
    playerName: "जोगेंद्र भाटी",
    playerFullName: "104 - जोगेंद्र भाटी",
    playerFatherName: "विजयराज जी भाटी",
    playerPhone: "9351691000",
    playerDob: "09-12-1981",
    playerAge: 43,
    playerSize: "-",
    playerRole: "विकेट कीपर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1viWYEoFk-bDJAD_Q2SgiX1xmDB1IqBRU",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
  {
    playerId: "105",
    playerName: "राकेश भाटी",
    playerFullName: "105 - राकेश भाटी",
    playerFatherName: "ओमप्रकाश जी भाटी",
    playerPhone: "9414651808",
    playerDob: "22-06-1991",
    playerAge: 33,
    playerSize: "L",
    playerRole: "बल्लेबाज",
    playerBatting: "बाएं हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1brPMNolS7BJktjE-h1YVFelOi8XRaAwy",
    playerStatus: "Available",
    playerSoldPrice: "4000",
    teamId: "",
    teamName: "सोलंकी फाइटर्स",
  },
  {
    playerId: "106",
    playerName: "गोपल कृष्णा राठौड़",
    playerFullName: "106 - गोपल कृष्णा राठौड़",
    playerFatherName: "अमरचंद जी राठौड़",
    playerPhone: "9929082802",
    playerDob: "20-06-1999",
    playerAge: 25,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ की स्पिन गेंदबाजी",
    playerImage: "1aJSqVsHmWEBbtVyN3pNjfX9bBSC14BXr",
    playerStatus: "Available",
    playerSoldPrice: "10000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "107",
    playerName: "नारायण लाल परिहार",
    playerFullName: "107 - नारायण लाल परिहार",
    playerFatherName: "गणपत लाल परिहार",
    playerPhone: "7742996766",
    playerDob: "21-05-1999",
    playerAge: 25,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1nPV7DjL1uOxvB9mgQ90sy7VyUj519TT4",
    playerStatus: "Available",
    playerSoldPrice: "7000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "108",
    playerName: "कुशल बोराणा",
    playerFullName: "108 - कुशल बोराणा",
    playerFatherName: "गणपत जी बोराणा",
    playerPhone: "9602102669",
    playerDob: "06-08-1999",
    playerAge: 25,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1XT4ZG8UwxByopvoWtlFa0aLH64y9bziX",
    playerStatus: "Available",
    playerSoldPrice: "1000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "109",
    playerName: "जवरीलाल राठौड़",
    playerFullName: "109 - जवरीलाल राठौड़",
    playerFatherName: "सोहन जी राठौड़",
    playerPhone: "8529219511",
    playerDob: "15-09-1998",
    playerAge: 26,
    playerSize: "L",
    playerRole: "ऑल राउंडर",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1j5-P6a1CEbM6LM2U8GqQ3_txU25YxkdJ",
    playerStatus: "Available",
    playerSoldPrice: "8000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "110",
    playerName: "आर्यन राठौड़",
    playerFullName: "110 - आर्यन राठौड़",
    playerFatherName: "सुदाराम जी राठौड़",
    playerPhone: "8485082104",
    playerDob: "09-05-1997",
    playerAge: 27,
    playerSize: "XL",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "दाहिने हाथ के तेज गेंदबाज",
    playerImage: "1HRINAP5_NCPDtwHVWoNQ90K20C8MhmfT",
    playerStatus: "Available",
    playerSoldPrice: "13000",
    teamId: "",
    teamName: "ट्रॉफी फाइटर्स",
  },
  {
    playerId: "111",
    playerName: "योगेश पँवार",
    playerFullName: "111 - योगेश पँवार",
    playerFatherName: "ललित कुमार पँवार",
    playerPhone: "-",
    playerDob: "06-05-2007",
    playerAge: 17,
    playerSize: "S",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1g9-0PylSe8pSCbyWxIMkzjgBsgIHO-bK",
    playerStatus: "Available",
    playerSoldPrice: "6000",
    teamId: "",
    teamName: "ज़ाफ़री सुपर",
  },
  {
    playerId: "112",
    playerName: "तुसार भाटी",
    playerFullName: "112 - तुसार भाटी",
    playerFatherName: "सूरजप्रकाश जी भाटी",
    playerPhone: "8233835600",
    playerDob: "12-07-2008",
    playerAge: 16,
    playerSize: "M",
    playerRole: "बल्लेबाज",
    playerBatting: "दाहिने हाथ का बल्लेबाज",
    playerBowling: "-",
    playerImage: "1CWfYRPi8xw_YYfBKCRc7rDSPYP8KbObs",
    playerStatus: "Available",
    playerSoldPrice: "5000",
    teamId: "",
    teamName: "श्री नाथ जी क्लब",
  },
];

export default players;
